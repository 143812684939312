@import '../../../../../mixins.scss';

.about-img{
    @include flex-column; 
    img{
        width: 1000px;
        height: 600px;
        object-fit: cover;
        @media (max-width: 420px) {
            width: 350px;
            height: 350px;
        }
        @media (max-width: 400px) {
            width: 350px;
            height: 350px;
        }
    }
}

.competition-name{
    @include flex-center;
    margin-top: 50px;
    margin-bottom: 50px;
    h3{
        text-align: center;
    }
}

.competition-top-blocks{
    @include flex-center;
    margin-bottom: 50px;
    .competition-contry{
        @include flex-center;
        justify-content: flex-start;
        margin-top: 10px;
        h6{
            margin-left: 15px;
            margin-top: 3px;
        }
        @media (max-width: 420px) {
            flex-direction: column;
        }
    }
    .competition-top-vertical-line{
        width: 1px;
        background-color: black;
        height: 200px;
        margin-left: 50px;
        margin-right: 50px;
    }
    h6{
        text-align: center;
        display: flex;
        align-items: center;
    }

    .competition-top{
        hr{
            margin-top: 30px;
            margin-bottom: 30px;
        }
    }
    @media (max-width: 420px) {
        flex-direction: column;
        // align-items: flex-start;
        .competition-top-vertical-line{
            display: none;
        }
        .competition-top{
            margin-top: 10px;
            hr{
                margin-top: 10px;
                margin-bottom: 10px;
            }
        }
    }
    // @media (max-width: 400px) {
    //     flex-direction: column;
    //     align-items: flex-start;
    //     .competition-top-vertical-line{
    //         display: none;
    //     }
    //     .competition-top{
    //         margin-top: 10px;
    //         hr{
    //             margin-top: 10px;
    //             margin-bottom: 10px;
    //         }
    //     }
    // }
}
.competition-category{
    margin: 10px;
//    border: 1px solid rgb(10, 10, 10); 
   padding: 5px 20px;
   .dynamic-button {
    display: inline-block;
    padding: 12px 25px;
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
    text-decoration: none;
    color: white;
    background: linear-gradient(45deg, #4a90e2, #667eea); /* Серо-синий градиент */
    border: none;
    border-radius: 30px;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    box-shadow: 0px 4px 15px rgba(102, 126, 234, 0.3);
    position: relative;
    overflow: hidden;
}

.dynamic-button:hover {
    background: linear-gradient(45deg, #667eea, #4a90e2); /* Обратный градиент */
    transform: scale(1.05);
    box-shadow: 0px 6px 20px rgba(102, 126, 234, 0.5);
}

.dynamic-button:active {
    transform: scale(0.98);
    box-shadow: 0px 2px 10px rgba(102, 126, 234, 0.3);
}

/* Анимация при наведении */
.dynamic-button::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 300%;
    height: 300%;
    background: rgba(255, 255, 255, 0.1);
    transition: width 0.5s ease-in-out, height 0.5s ease-in-out, top 0.5s ease-in-out, left 0.5s ease-in-out;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    z-index: 0;
}

.dynamic-button:hover::before {
    width: 0;
    height: 0;
}

.dynamic-button span {
    position: relative;
    z-index: 1;
}    
}
.competition-category{
    @include flex-center;
    @media (max-width: 420px) {
        img{
            width: 350px;
            height: 200px;   
        }
    }
    @media (max-width: 400px) {
        img{
            width: 350px;
            height: 200px;   
        }
    }
}
.text-competition-content{
    @include flex-center; 
    margin-top: 50px;
    margin-bottom: 50px;
    @media (max-width: 420px) {
        flex-direction: column;
        align-items: center;
        width: 350px;
        img{
            width: 350px;
        }
    }
    @media (max-width: 400px) {
        flex-direction: column;
        align-items: center;
        width: 350px;
        img{
            width: 350px;
        }
    }
}