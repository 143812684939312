@import '../../../../../mixins.scss';

.publication-page{
    @include flex-center;
    flex-direction: column;
    .about-img{
        @include flex-center;
        img{
            width: 770px;
        }
        @media (max-width: 420px) {
            img{
            width: 350px;
            height: 350px;
            }
        }
        @media (max-width: 400px) {
            img{
            width: 350px;
            height: 350px;
            }
        }
    }
    .about-prea{
        width: 770px;
        @media (max-width: 400px) {
            width: 350px;
        }
        @media (max-width: 420px) {
            width: 350px;
        }
    }
    .social-share{
       .section-tittle{
        width: 770px;
        @include flex-center;
        justify-content: flex-start;
        ul{
            @include flex-center;
            margin: 0;
            padding: 0;
            li{
                list-style-type: none;
                margin: 0 2px;
            }
        }
       }
    }
    .competition-category{
        margin: 10px;
    //    border: 1px solid rgb(10, 10, 10); 
       padding: 5px 20px;
       .dynamic-button {
        display: inline-block;
        padding: 12px 25px;
        font-size: 18px;
        font-weight: bold;
        text-transform: uppercase;
        text-decoration: none;
        color: white;
        background: linear-gradient(45deg, #4a90e2, #667eea); /* Серо-синий градиент */
        border: none;
        border-radius: 30px;
        transition: all 0.3s ease-in-out;
        cursor: pointer;
        box-shadow: 0px 4px 15px rgba(102, 126, 234, 0.3);
        position: relative;
        overflow: hidden;
    }
    
    .dynamic-button:hover {
        background: linear-gradient(45deg, #667eea, #4a90e2); /* Обратный градиент */
        transform: scale(1.05);
        box-shadow: 0px 6px 20px rgba(102, 126, 234, 0.5);
    }
    
    .dynamic-button:active {
        transform: scale(0.98);
        box-shadow: 0px 2px 10px rgba(102, 126, 234, 0.3);
    }
    
    /* Анимация при наведении */
    .dynamic-button::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 300%;
        height: 300%;
        background: rgba(255, 255, 255, 0.1);
        transition: width 0.5s ease-in-out, height 0.5s ease-in-out, top 0.5s ease-in-out, left 0.5s ease-in-out;
        border-radius: 50%;
        transform: translate(-50%, -50%);
        z-index: 0;
    }
    
    .dynamic-button:hover::before {
        width: 0;
        height: 0;
    }
    
    .dynamic-button span {
        position: relative;
        z-index: 1;
    }    
    }
    .comeback-form{
        @include flex-center;
        margin-top: 20px;
        .form-contact{
            width: 770px;
            .text-place{
                @include flex-center;
                flex-direction: column;
                .first-place{
                    width: 600px;
                }
                .second-place{
                    @include flex-center;
                    div{
                        width: 290px;
                        margin: 10px 10px;
                    }
                }
                .therd-place{
                    width: 600px;
                }
            }
            .publicate-post-button{
                margin: 10px 0 0 85px;
                button{
                    background-color: white;
                    border: 1px solid rgb(207, 204, 204);
                    border-radius: 3px;
                    width: 150px;
                    height: 70px;
                }
            }
        }
    }
}